import * as React from 'react';
import {FormEvent, useEffect, useRef, useState} from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import Tooltip from "@mui/material/Tooltip";
import {useBasketContext} from "../../context/BasketContext";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Typography from "@mui/material/Typography";
import {Badge, Divider, Stack, TextField} from "@mui/material";
import BasketItem from "./BasketItem";
//@ts-ignore
import Sushi from '../../assets/sushi.svg';
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import {useTownContext} from "../../context/TownsContext";
// @ts-ignore
import Logo from "../../assets/logo.png";
import OrderComplete from "../OrderComplete/OrderComplete";
import {ImgUrl} from "../ProductCard/ProductCard";

type ProductType = {
    name: string,
    description: string,
    price: string,
    weight: string,
    pieces: string,
    img: ImgUrl,
    quantity: number;
};


export default function Basket() {
    const {town} = useTownContext()
    const {basket, setBasket} = useBasketContext();
    const [open, setOpen] = React.useState(false);
    const myComponentRef = useRef<HTMLDivElement | null>(null);
    const [name, setName] = useState<string>('');
    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const [info, setInfo] = useState<string>('');
    const [openDialog, setOpenDialog] = React.useState(false);

    const handleClickOpen = () => {
        setOpenDialog(true);
    };

    const handleClose = () => {
        setOpenDialog(false);
    };
    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        function handleClickOutside(event: MouseEvent): void {
            //@ts-ignore
            if (myComponentRef.current && !myComponentRef.current?.contains(event.target as Node)) {
                handleDrawerClose()
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [myComponentRef]);
    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const botToken = '6921453143:AAHKrG1tVptm8NbnjZqLsqiBuv_3IjlUVgU';
        const text = ` 
         🔔НОВЕ ЗАМОВЛЕННЯ!🔔 \n \n👤Ім'я клієнта: ${name} \n📱Номер телефону клієнта: ${phoneNumber} \n💾Додаткова інформація: ${info} \n \n🥢Замовлення: \n${formatProducts(basket as ProductType[]).map(product => product).join('\n')} \n \n💰Загальна сума замовлення ${(basket as ProductType[]).reduce((acc: number, product: ProductType) => {
            //@ts-ignore
             return acc + (Number(product?.price) * product.quantity);
        }, 0)} грн            
        `

        try {
            const encodedText = encodeURIComponent(text);

            //@ts-ignore
            const response = await axios.post(`https://api.telegram.org/bot${botToken}/sendMessage?chat_id=${town?.attributes.specialNumber}&parse_mode=html&text=${encodedText}`);

            if (response.status === 200) {
                // Тут можна додати логіку очищення форми або виведення повідомлення користувачеві
            } else {
                console.error('Помилка відправки:', response);
            }
        } catch (error) {
            console.error('Помилка відправки:', error);
        }
    };


    function formatProducts(products: ProductType[]): string[] {
        return products.map(product => `- ${product.name} - Кількість: ${product.quantity}, Ціна: ${product.price} грн`);
    }


    const basketContainer = (anchor: string) => (
        <Box
            ref={myComponentRef}
            sx={{
                width: {lg: 800}, height: '100vh',
                overflowY: 'auto',
                '&::-webkit-scrollbar': {
                    border: '100px',
                    width: '2px',
                },
                '&::-webkit-scrollbar-track': {
                    background: '#888',
                },
                '&::-webkit-scrollbar-thumb': {
                    background: '#555',
                },
                '&::-webkit-scrollbar-thumb:hover': {
                    background: '#4D4A43',
                },
                scrollbarWidth: 'thin',
                scrollbarColor: '#888 #f1f1f1',
                backgroundColor: '#000'
            }}
            py={5}
            role="presentation"
            display='flex'
            flexDirection='column'
            justifyContent='space-between'
            color={'#fff'}
            onKeyDown={handleDrawerOpen}
        >
            <Box position='absolute' top='10px' right='10px' onClick={handleDrawerClose}>
                <CloseIcon sx={{color: '#fff'}} cursor='pointer'/>
            </Box>
            {basket && basket.length > 0 ?
                <Box mb={2}>
                    <Box px={2} mb={2} display='flex' alignItems='center' justifyContent='space-between'>
                        <Typography
                            variant='h3'>
                            Ваше замовлення:
                        </Typography>
                        <img style={{width: '70px'}} src={Sushi} alt=""/>
                    </Box>
                    <Divider/>
                    {basket.map((product, index) => {
                        return (
                            <BasketItem
                                key={product.name}
                                product={product}/>
                        )
                    })}
                    <Box
                        pt={2}
                        pb={{xs: 2, md: 0}} sx={{width: '95%'}} mx='auto'>
                        <form onSubmit={async (event) => {
                            await handleSubmit(event)
                            setBasket([])
                            handleClickOpen()
                        }}>
                            <Stack gap={2}>
                                <Typography pb={{xs: 2, md: 0}} variant='h4' color='white'>Вкажіть ваші <br/>контактні
                                    дані</Typography>
                                <Tooltip title="Вкажіть як до Вас звертатися" placement="left">
                                    <TextField
                                        label="Ім'я"
                                        required
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        variant="outlined"
                                    />
                                </Tooltip>
                                <Tooltip title="Щоб ми Вам зателефонували" placement="left">
                                    <TextField
                                        type="text"
                                        label="Номер телефону"
                                        required
                                        value={phoneNumber}
                                        onChange={(e) => setPhoneNumber(e.target.value)}
                                        placeholder="Номер телефону"
                                    />
                                </Tooltip>
                                <Tooltip title="Якщо у вас є додаткова інформація до замовлення, обов'язково її вкажіть"
                                         placement="left">
                                    <TextField
                                        type="text"
                                        label="Додаткова інформація"
                                        multiline
                                        rows={3}
                                        value={info}
                                        onChange={(e) => setInfo(e.target.value)}
                                        placeholder="Додаткова інформація"
                                    />
                                </Tooltip>
                                <Tooltip title="Ви можете змінити місто у верхньому правому кутку" placement="left">
                                    <TextField
                                        disabled
                                        type="number"
                                        //@ts-ignore
                                        label={town?.attributes.nameUK}
                                        onChange={(e) => setInfo(e.target.value)}
                                    />
                                </Tooltip>
                                <Button sx={{backgroundColor: '#fff', py: '10px'}} variant='outlined' type="submit">Підтвердити
                                    замовлення</Button>
                            </Stack>
                        </form>
                    </Box>
                </Box> :
                <Box
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                    height='100vh'
                >
                    <ShoppingCartIcon sx={{fontSize: '3rem', marginRight: '12px'}}/>
                    <Typography variant='subtitle1' fontSize={'1.5rem'}>
                        Додайте товари у кошик.
                    </Typography>
                </Box>
            }

        </Box>
    );

    return (
        <div>
            <React.Fragment>
                <Badge sx={{}} badgeContent={basket.length} color="primary">
                    <Tooltip placement='right' followCursor title="Кошик">
                        <ShoppingBasketIcon
                            onClick={handleDrawerOpen}
                            sx={{
                                fontSize: '2rem',
                                transition: '0.3s',
                                cursor: 'pointer',
                                ":hover": {color: '#EE2D2E'}
                            }}/>
                    </Tooltip>
                </Badge>
                <Drawer
                    anchor={"right"}
                    open={open}
                >
                    {basketContainer("right")}
                    {basket && basket.length > 0 ?
                        <Box display='flex' justifyContent='space-between' p={2}>
                            <Box>
                                <Typography text-align='right' fontSize='1.5rem'>Разом:</Typography>
                                <Typography fontSize='2rem' color='text.secondary'>
                                    {(basket as ProductType[]).reduce((acc: number, product: ProductType) => {
                                        //@ts-ignore
                                        return acc + (Number(product?.price) * product.quantity);
                                    }, 0)} грн
                                </Typography>
                            </Box>
                            <img className='logo' src={Logo} alt=""/>
                        </Box> : <Box> </Box>}
                </Drawer>
                <OrderComplete
                    open={openDialog}
                    onClose={handleClose}
                />
            </React.Fragment>
        </div>
    );
}