import ProductCard from "../../components/ProductCard/ProductCard";
import {ScrollableBox} from "../../components/ScrolableBox/ScrolableBox";
import {useQuery} from "react-query";
import {getProductsByCategory} from "../../api/products/getProductsByCategory";
import {Category, Product} from "./Menu.types";
import Box from "@mui/material/Box";
import React from "react";
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-cards';
// import required modules

const MenuPage = (props: Category) => {
    const {id} = props;
    const {data, isLoading} = useQuery(`${id}`, () => getProductsByCategory(Number(id) + 1), {
        staleTime: Infinity
    })
    if (isLoading && !data) return <div>Завантаження...</div>
    return (
        <Box sx={{
            paddingTop: '15vh',
            paddingLeft: {xs: '0px', md: '150px'},
            paddingRight: {xs: '0px', md: '150px'},
        
        }}>
            <ScrollableBox>
                {data.data.map((product: Product) => {
                    return <ProductCard
                        key={product.id}
                        id={product.id}
                        product={{...product.attributes, quantity: 1}}
                    />
                })}
            </ScrollableBox>
            <Box display={{xs: 'flex', md: 'none'}}
                 gap={3}
                 justifyContent='center'
                 sx={{
                     height: '75vh',
                     overflowY: 'auto',
                     paddingBottom: '100px',
                     '&::-webkit-scrollbar': {
                         border: '100px',
                         width: '2px',
                     },
                     '&::-webkit-scrollbar-track': {
                         background: '#888',
                     },
                     '&::-webkit-scrollbar-thumb': {
                         background: '#555',
                     },
                     '&::-webkit-scrollbar-thumb:hover': {
                         background: '#4D4A43',
                     },
                     scrollbarWidth: 'thin',
                     scrollbarColor: '#888 #f1f1f1',
                 }}
                 position='relative' flexWrap='wrap' zIndex={100000000}>
                {data.data.map((product: Product) => {
                    return (
                        <ProductCard
                            key={product.id}
                            id={product.id}
                            product={{...product.attributes, quantity: 1}}
                        />
                    )
                })}
            </Box>
        </Box>
    )
}
export default MenuPage;