import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {useBasketContext} from "../../context/BasketContext";
import {useTownContext} from "../../context/TownsContext";

export type ProductCardProps = {
    product: {
        name: string,
        description: string,
        price: string,
        weight: string,
        count: string,
        img: ImgUrl,
        quantity: number;
    },
    id: number
}
export type ImgUrl = {
    data: {
        attributes: {
            url: string
        }
    }
}

export function increaseAndRound(num: number, percentage?: number): number {
    if(!percentage) return num

    // Збільшуємо число на заданий відсоток
    let increasedValue = num * (1 + percentage / 100);

    // Отримуємо останню цифру числа
    let lastDigit = increasedValue % 10;

    if (lastDigit === 0 || lastDigit === 5) {
        // Якщо остання цифра 0 або 5, залишаємо число без змін
        return Math.round(increasedValue);
    } else if (lastDigit < 5) {
        // Якщо остання цифра менша за 5, округлюємо до найближчих 5
        return Math.floor(increasedValue / 10) * 10 + 5;
    } else {
        // Якщо остання цифра 6 або більше, округлюємо до наступного десятка
        return Math.ceil(increasedValue / 10) * 10;
    }
}


export const baseUrl = "https://admin.kisomi-sushi.com";
const ProductCard = (props: ProductCardProps) => {
    const {product} = props
    const imageUrl = `${baseUrl}${product?.img?.data?.attributes.url}`;
    const {basket, setBasket} = useBasketContext();
    const isProductInBasket = basket.some(item => item.name === product?.name);
    const {town, setTown} = useTownContext()

    const addProductToBasket = () => {
        //@ts-ignore
        setBasket((currentBasket) => [...currentBasket, product]);
    };

    //@ts-ignore
    console.log(town)
    return (
        <Card sx={{width: 345, height: 'auto'}}>
            <CardMedia
                sx={{height: 250}}
                image={imageUrl && imageUrl}
                title={product?.name}
                component="img"
                loading="lazy"
            />

            <CardContent sx={{padding: '8px 16px 0 16px'}}>
                <Typography mb={0} fontSize='22px' gutterBottom variant="subtitle2" component="div">
                    {product?.name}
                </Typography>
                <Typography fontSize='16px' variant="body2" color="text.secondary">
                    <span className='weight'>{product?.weight} г.</span> <span
                    className='weight'>{product.count} шт.</span> - {product?.description}
                </Typography>
            </CardContent>
            <CardActions>
                <Button
                    onClick={addProductToBasket}
                    size="medium" variant='contained'
                    sx={{marginRight: '30px'}}
                    disabled={isProductInBasket}>
                    <Typography fontSize='20px'>
                        В кошик
                    </Typography>
                </Button>
                <Typography fontSize='20px'>
                    {// @ts-ignore
                        product?.price},00 грн
                </Typography>
            </CardActions>
        </Card>
    );
}
export default ProductCard