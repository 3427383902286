import {baseUrl, ImgUrl} from "../ProductCard/ProductCard";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import * as React from "react";
import {useBasketContext} from "../../context/BasketContext";
import Button from "@mui/material/Button";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import DeleteIcon from '@mui/icons-material/Delete';
import ClearIcon from '@mui/icons-material/Clear';
import {useTownContext} from "../../context/TownsContext";

export type BasketItemProps = {
    product: {
        name: string,
        description: string,
        price: string,
        weight: string,
        pieces: string,
        img: ImgUrl,
        quantity: number;
    }
}




// Приклад використання:

const BasketItem = ({product}: BasketItemProps) => {
    const {name, price, pieces, weight, img} = product;
    const {setBasket} = useBasketContext();
    const imageUrl = `${baseUrl}${img?.data?.attributes.url}`;
    const {town, setTown} = useTownContext()

    const deleteroductInBasket = (productName: string) => {
        //@ts-ignore
        setBasket(currentBasket => currentBasket.filter(item => item.name !== productName));
    };

    const incrementQuantity = (productName: string) => {
        setBasket(currentBasket =>
            currentBasket.map(item =>
                item.name === productName ? {...item, quantity: item?.quantity + 1} : item
            )
        );
    };

    const decrementQuantity = (productName: string) => {
        setBasket(currentBasket =>
            currentBasket.map(item =>
                item.name === productName ? {...item, quantity: Math.max(item?.quantity - 1, 1)} : item
            )
        );
    };

    return (
        <Box key={name} display='flex' justifyContent='space-between' alignItems={{xs: 'flex-start', sm: 'center'}}
             gap={2} px={2}
             borderBottom='1px solid #EE2D2E' padding='10px 20px'
             color='#fff'
             position='relative'
             flexDirection={{xs: 'column', sm: 'row'}}>
            <Box display='flex' gap={5} maxWidth='300px'>
                <img src={imageUrl}  style={{width: '150px'}} alt=""/>
                <Box display='flex' flexDirection='column'>
                    <Typography variant='subtitle1' fontSize='1.2rem' width='100px'>
                        {name}
                    </Typography>
                    <Box display='flex' color='text.secondary'>
                        <Typography mr={2} variant='subtitle2' fontSize='1rem'>
                            {weight} г.
                        </Typography>
                        <Typography variant='subtitle2' fontSize='1rem'>
                            {pieces} шт.
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center'
                 width={{xs: '100%', sm: 'auto'}}>
                <Box gap={2.5} display='flex' alignItems='center' justifyContent='center'>
                    <Button variant='contained' onClick={() => decrementQuantity(name)}><RemoveIcon/></Button>
                    <Typography fontSize='1rem'>{product.quantity}</Typography>
                    <Button variant='contained' onClick={() => incrementQuantity(name)}><AddIcon/></Button>
                </Box>
            </Box>
            <Box display='flex' flexDirection='column' color='#fff' width={{xs: '100%', sm: 'auto'}} textAlign='right'>
                <Typography color='#fff' variant='subtitle1' fontSize='1.2rem'>
                    {product?.price} грн.
                </Typography>
            </Box>
            <Button sx={{display: {xs: 'none', sm: 'block'}}} variant='contained'
                    onClick={() => deleteroductInBasket(name)}><DeleteIcon/></Button>
            <Button sx={{position: 'absolute', right: '5px', display: {xs: 'block', sm: 'none'}}}
                    onClick={() => deleteroductInBasket(name)}><ClearIcon/></Button>
        </Box>
    )
}
export default BasketItem;